
import { defineComponent } from "vue";
import store from "@/store";
import axios from "axios";
import { ElMessage } from "element-plus";

declare const window: any;
export default defineComponent({
  name: "settingPage",
  data() {
    return {
      updateEventID: "",
      updateGroupID: "",
      url: "",
      switchGroupID1: "",
      switchUserID1: "",
      switchGroupID2: "",
      switchUserID2: "",
    };
  },
  watch: {
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    } else if (store.state.uid != 1) {
      this.$router.push({name: '404'});
    }

    if (this.uidValue != '1') {
      this.$router.push({name: '404'});
    }
  },
  methods: {
    switchPlayers() {
      console.log("Switch Players")
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=switchPlayers&switchGroupID1="+this.switchGroupID1+
          "&switchUserID1="+this.switchUserID1+"&switchGroupID2="+this.switchGroupID2+"&switchUserID2="+this.switchUserID2,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          if (response.data == "success") {
            ElMessage({
              message: this.$t("success"),
              type: "success",
            });
          } else {
            ElMessage({
              message: this.$t("error"),
              type: "error",
            });
          }
        })
    },
    updateEvent() {
      console.log("Update Event"+this.updateEventID)
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=updateEvent&eventID="+this.updateEventID,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          for (let i = 0; i < response.data.length; i++) {
              setTimeout(() => {
                  console.log(response.data[i])
                  this.updateGroup(response.data[i])
              }, i*2000);
          }
        })
    },
    updateGroup(groupInput) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=updateGroup&groupInput="+groupInput,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          if(response.data == true) {
            ElMessage.success(this.$t("updated")+": "+groupInput);
          }
          console.log(response.data)
        })
    },
    downloadCSV() {
      console.log("Download CSV")
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=downloadCSV",
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          // Convert the CSV data to a Blob
          const blob = new Blob([response.data], { type: 'text/csv;charset=big5;' });

          // Create a link element
          const link = document.createElement('a');
          if (link.download !== undefined) { // Feature detection
            // Create a URL for the Blob and set it as the href attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'NewContact.csv');
            
            // Append the link to the body
            document.body.appendChild(link);
            
            // Trigger the download by simulating a click
            link.click();
            
            // Clean up and remove the link
            document.body.removeChild(link);
          }
        })
    }
  },
});
