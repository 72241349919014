
import { defineComponent } from "vue";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import { ElMessage } from "element-plus";
import { useField, useForm } from "vee-validate";

declare const window: any;
export default defineComponent({
  name: "settingPage",

  data() {
    return {
      displayTourID: "",
      displayTourDoubleID: "",
      createSingleGroupID: "",
      createDoubleGroupID: "",
      tourTable: [],
      tourTableDouble: [],
    };
  },
  watch: {
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    } else if (store.state.uid != 1) {
      this.$router.push({name: '404'});
    }

    if (this.uidValue != '1') {this.$router.push({name: '404'});}

  },
  methods: {
    displayApplyInfo() {
      console.log("displayApplyInfo: "+this.displayTourID)
      axios
        .post(
          //plugin.php?id=won_tour:tour&tab=cp&mode=cp_tour&sub=cp_buddy_single&action=listjoin
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cpTour&action=listjoin&tourid="+this.displayTourID,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          this.tourTable = response.data
        })
    },
    displayApplyInfoDouble() {
      console.log("displayApplyInfoDouble: "+this.displayTourDoubleID)
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cpTour&action=listjoinDouble&tourid="+this.displayTourDoubleID,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          this.tourTableDouble = response.data;
        })
    },
    createSingleGames() {
      console.log("createSingleGames: "+this.createSingleGroupID)
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cpTour&action=createSingleGames&groupid="+this.createSingleGroupID,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          if (response.data == "success") {
            ElMessage({
              message: this.$t("createSingleGamesSuccess"),
              type: "success",
            });
          } else {
            ElMessage({
              message: this.$t("createFail"),
              type: "error",
            });
          }
        })
    },
    createDoubleGames() {
      console.log("createDoubleGames: "+this.createDoubleGroupID)
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cpTour&action=createDoubleGames&groupid="+this.createDoubleGroupID,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          if (response.data == "success") {
            ElMessage({
              message: this.$t("createDoubleGamesSuccess"),
              type: "success",
            });
          } else {
            ElMessage({
              message: this.$t("createFail"),
              type: "error",
            });
          }
        })
    }
  },
});
