import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header card-header-stretch" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "nav nav-tabs nav-line-tabs nav-stretch fs-5 border-0" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = {
  class: "tab-content",
  id: "myTabContent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_adminTourCommonDiv = _resolveComponent("adminTourCommonDiv")!
  const _component_adminTourGroupingDiv = _resolveComponent("adminTourGroupingDiv")!
  const _component_adminTourPrizeDiv = _resolveComponent("adminTourPrizeDiv")!
  const _component_adminTourNoticeDiv = _resolveComponent("adminTourNoticeDiv")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(
                'cursor-pointer nav-link ' +
                (this.$route.params.tab == 'adminTourCommon' ? 'active' : '')
              ),
              to: {name: 'admin', params: { mode: 'tour', tab: 'adminTourCommon'}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("adminTour")), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(
                'cursor-pointer nav-link ' +
                (this.$route.params.tab == 'adminTourGrouping' ? 'active' : '')
              ),
              to: {name: 'admin', params: { mode: 'tour', tab: 'adminTourGrouping'}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("adminTourGrouping")), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(
                'cursor-pointer nav-link ' +
                (this.$route.params.tab == 'adminTourPrize' ? 'active' : '')
              ),
              to: {name: 'admin', params: { mode: 'tour', tab: 'adminTourPrize' }}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("prize")), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(
                'cursor-pointer nav-link ' +
                (this.$route.params.tab == 'adminTourNotice' ? 'active' : '')
              ),
              to: {name: 'admin', params: { tab: 'adminTourNotice' }}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("notice")), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          class: _normalizeClass(
            'tab-pane fade ' +
            (this.$route.params.tab == 'adminTourCommon' ? 'show active' : '')
          ),
          id: "profile_pane",
          role: "tabpanel"
        }, [
          _createVNode(_component_adminTourCommonDiv)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(
            'tab-pane fade ' +
            (this.$route.params.tab == 'adminTourGrouping' ? 'show active' : '')
          ),
          id: "profile_pane",
          role: "tabpanel"
        }, [
          _createVNode(_component_adminTourGroupingDiv)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(
            'tab-pane fade ' +
            (this.$route.params.tab == 'adminTourPrize' ? 'show active' : '')
          ),
          id: "pe_pane",
          role: "tabpanel"
        }, [
          _createVNode(_component_adminTourPrizeDiv)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(
            'tab-pane fade ' +
            (this.$route.params.tab == 'adminTourNotice' ? 'show active' : '')
          ),
          id: "notice_pane",
          role: "tabpanel"
        }, [
          _createVNode(_component_adminTourNoticeDiv)
        ], 2)
      ])
    ])
  ]))
}