
import { defineComponent } from "vue";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import { ElMessage } from "element-plus";
import { useField, useForm } from "vee-validate";
import adminTourGroupingDiv from "@/views/pages/admin/tour/adminTourGrouping.vue";
import adminTourCommonDiv from "@/views/pages/admin/tour/adminTourCommon.vue";

declare const window: any;
export default defineComponent({
  name: "settingPage",
  components: {
    adminTourGroupingDiv,
    adminTourCommonDiv
  },
  data() {
    return {
      messageList: []
    };
  },
  watch: {
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    } else if (store.state.uid != 1) {
      this.$router.push({name: '404'});
    }

    if (this.uidValue != '1') {
      this.$router.push({name: '404'});
    }

    this.getNoticeMessage()
  },
  methods: {
    getNoticeMessage() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=getNoticeMessage",
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          //console.log(response.data)
          this.messageList = response.data
        })
    },
    copyNoticeMessage(item){
      navigator.clipboard.writeText(item.replace(/<br>/g, '\n'))
      .then(() => {
        ElMessage.success(this.$t("copied"));
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
    testWhatsapp(){
      console.log("testWhatsapp")
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=whatsapp_api&action=test",
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
        })
    },
  },
});
