import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-label" }
const _hoisted_2 = { class: "indicator-label" }
const _hoisted_3 = { class: "form-label mt-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "col-2" }
const _hoisted_7 = { class: "col-2" }
const _hoisted_8 = { class: "col-2" }
const _hoisted_9 = { class: "col-3" }
const _hoisted_10 = { class: "indicator-label" }
const _hoisted_11 = { class: "form-label mt-5" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-3" }
const _hoisted_14 = { class: "col-3" }
const _hoisted_15 = { class: "indicator-label" }
const _hoisted_16 = { class: "form-label mt-5" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col-3" }
const _hoisted_19 = { class: "col-3" }
const _hoisted_20 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("contactCsv")) + " :", 1),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadCSV())),
      class: "btn btn-sm btn-primary"
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("adminDownload")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("switchPlayers")) + " :", 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.switchGroupID1) = $event)),
          placeholder: "Group ID #1"
        }, null, 512), [
          [_vModelText, this.switchGroupID1]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.switchUserID1) = $event)),
          placeholder: "User ID #1"
        }, null, 512), [
          [_vModelText, this.switchUserID1]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.switchGroupID2) = $event)),
          placeholder: "Group ID #2"
        }, null, 512), [
          [_vModelText, this.switchGroupID2]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.switchUserID2) = $event)),
          placeholder: "User ID #2"
        }, null, 512), [
          [_vModelText, this.switchUserID2]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.switchPlayers())),
          class: "btn btn-sm btn-primary"
        }, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("switchBtn")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("updageEvent")) + " :", 1),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.updateEventID) = $event))
        }, null, 512), [
          [_vModelText, this.updateEventID]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateEvent())),
          class: "btn btn-sm btn-primary"
        }, [
          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("update")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("updageGroup")) + " :", 1),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.updateGroupID) = $event))
        }, null, 512), [
          [_vModelText, this.updateGroupID]
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("button", {
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateGroup(this.updateGroupID))),
          class: "btn btn-sm btn-primary"
        }, [
          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("update")), 1)
        ])
      ])
    ])
  ], 64))
}