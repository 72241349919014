
import { defineComponent } from "vue";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import { ElMessage } from "element-plus";
import { useField, useForm } from "vee-validate";
import adminTourGroupingDiv from "@/views/pages/admin/tour/adminTourGrouping.vue";
import adminTourCommonDiv from "@/views/pages/admin/tour/adminTourCommon.vue";
import adminTourPrizeDiv from "@/views/pages/admin/tour/adminTourPrize.vue";
import adminTourNoticeDiv from "@/views/pages/admin/tour/adminTourNotice.vue";

declare const window: any;
export default defineComponent({
  name: "settingPage",
  components: {
    adminTourGroupingDiv,
    adminTourCommonDiv,
    adminTourPrizeDiv,
    adminTourNoticeDiv
  },
  data() {
    return {
      updateEventID: "",
      updateGroupID: "",
      copyMessageText:"",
      copyMessageText2:"",
      displayPrizeEvent: "", //30,31,32,33
      prizeData: [],
      trophyArray: [],
      userProfile: [],
      area: {},
      mobile: "",
      gender: {},
      birthdayDate: "",
      practice: {},
      bio: "",
      fbid: "no",
      files: [] as any,
      url: "",
      messageList: []
    };
  },
  watch: {
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    } else if (store.state.uid != 1) {
      this.$router.push({name: '404'});
    }

    if (this.uidValue != '1') {
      this.$router.push({name: '404'});
    }
  },
  methods: {
    
  },
});
